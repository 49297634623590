<template>
  <div id="TableContainer">
    <div>
      <Header table="ticketRules"
              ref="counter"
              @search="search"
              @deleteAll="deleteTicketRule"
              v-bind:hidePanel="hidePanel"
      />
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>

    <hr>
    <div id="table">
      <TicketRuleList
          v-bind:ticketRules="changedTicketRules"
          :key="updateKey"
          @setHeaderVisible="setHeaderVisible"
          @setPanelVisible="setPanelVisible"
      />
    </div>
  </div>
</template>

<script>
import TicketRuleList from "./TicketRuleList";
import Header from "../Header";
import axios from "axios";
import Link from "../utils/Link";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import VueConfirmDialog from "vue-confirm-dialog";
import ui from "../utils/ui";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

let tableHeaderPosition = [0];

export default {
  components: {
    Header,
    TicketRuleList
  },
  computed: {
    changedTicketRules: function () {
      return this.ticketRules.filter(x => this.lastQuery(x, this.isInclude));
    }
  },

  mounted() {
    this.getTicketRules().then(() => {
      this.alignColumns()
      this.$emit("changeComponent", 'ticketRules');
    });
  },

  data() {
    return {
      ticketRules: [],
      hidePanel: true,
      array: [],
      updateKey: 0,
      lastQuery: (x) => x
    };
  },

  created: function () {
    window.addEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  methods: {
    search(qsearch) {
      this.lastQuery = qsearch;
    },

    fieldContain(query, field) {
      if (field !== null) {
        return field.toLowerCase().indexOf(query) !== -1;
      }
    },

    isInclude(ticketRule, query) {
      return this.fieldContain(query, this.$t("ticketType." + ticketRule.ticketType))
          || this.fieldContain(query, ticketRule.comments)
          || this.fieldContain(query, ticketRule.assigned)
          || this.fieldContain(query, this.$t("recordStatus." + ticketRule.status));
    },

    setPanelVisible(array) {
      this.array = array;
      this.$refs.counter.changeLabel(array.length, 'ticketRules');
      this.hidePanel = false;
    },

    setHeaderVisible() {
      this.hidePanel = true;
    },

    getTicketRules() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getTicketRulesUrl(),
        {headers}
      ).then(res => {
        this.ticketRules = res.data;
      }).catch(() => {
        this.createToast(this.$t("mainTicketRule.ticketRulesNotFound"), "error")
      });
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 3000
      });
    },

    windowResize() {
      this.alignColumns();
    },

    scrolling() {
      this.alignColumns();
      ui.methods.stickHeader(tableHeaderPosition);
    },

    alignColumns() {
      if (this.ticketRules.length > 0) {
        let rowWidth = 0;
        rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketRuleCheckboxTd', 'th.ticketRuleCheckbox');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketRuleTypeTd',     'th.ticketRuleType');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketRuleCommentsTd', 'th.ticketRuleComments');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketRuleAssignedTd', 'th.ticketRuleAssigned');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketRuleStatusTd',   'th.ticketRuleStatus');
        ui.methods.copyElementWidthIfDifferent(rowWidth, '#tableHead');
      }
    },

    deleteTicketRule() {
      this.$confirm(
        {
          message: this.$t("mainTicketRule.confirmMessage"),
          button: {
            no: this.$t("mainTicketRule.confirmNo"),
            yes: this.$t("mainTicketRule.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              let headers = Link.methods.getHeaders();
              const promises = [];
              for (let i = 0; i < this.array.length; i++) {
                promises.push(axios.delete(Link.methods.getTicketRuleDeleteUrl(this.array[i]), {headers}));
              }
              Promise.all(promises).then(() => {
                this.array.slice(0);
                this.getTicketRules();
                this.setHeaderVisible()
                this.updateKey += 1;
              }).catch(() => {
                this.array.slice(0);
                this.getTicketRules();
                this.setHeaderVisible()
                this.updateKey += 1;
                this.createToast(this.$t("mainTicketRule.unableToDeleteSelectedRulesToast"), "error");
              });
            }
          }
        }
      )
    }
  }
}

</script>

<style scoped lang="scss">
@import "../../../public/styles/tables.scss";
</style>