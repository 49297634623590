<template>
  <table>
    <thead id="tableHead">
      <tr>
        <th class="ticketRuleCheckbox">
          <input type="checkbox" id="titleCheckbox" @change="checkAll">
        </th>

        <th class="ticketRuleType" @click="sortByField('ticketType', 'ticketType')">
          <div class="parent">
            <div class="left">
              {{ $t("ticketRuleList.ticketType") }}
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('ticketType') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('ticketType') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>

        <th class="ticketRuleComments" @click="sortByField('comments')">
          <div class="parent">
            <div class="left">
              {{ $t("ticketRuleList.comments") }}
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('comments') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('comments') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>

        <th class="ticketRuleAssigned" @click="sortByField('assigned')">
          <div class="parent">
            <div class="left">
              {{ $t("ticketRuleList.assigned") }}
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('assigned') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('assigned') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>

        <th class="ticketRuleStatus" @click="sortByField('status', 'recordStatus')">
          <div class="parent">
            <div class="left">
              {{ $t("ticketRuleList.status") }}
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('status') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('status') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
    <SoloTicketRule
        ref="ticketRuleList"
        v-for="ticketRule of ticketRules" :key="ticketRule.id"
        v-bind:ticketRule="ticketRule"
        @addRaw="changeDiv"
    />
    </tbody>
  </table>
</template>

<script>
import SoloTicketRule from "./SoloTicketRule";
import $ from "jquery";
import App from "../../App";
import {GlobalLocalizationEventEmitter} from "@/i18n";

export default {
  components: {SoloTicketRule},
  props: ['ticketRules'],

  data() {
    return {
      checked: false,
      field: '',
      checkedTicketRulesList: [],
      orderBy: false,
      clicked: false
    }
  },

  created() {
    GlobalLocalizationEventEmitter.$on("localizationChanged", () => {
      this.restoreOrders();
    })
  },

  beforeDestroy() {
    GlobalLocalizationEventEmitter.$off("localizationChanged", this);
  },

  watch: {
    ticketRules: function () {
      this.restoreOrders();
    }
  },

  methods: {
    changeDiv() {
      let ticketList = this.$refs.ticketRuleList;
      this.checkedTicketRulesList = [];
      ticketList.forEach(element => {
        if (element.$refs.checkbox.checked) {
          this.checkedTicketRulesList.push(element.ticketRule.id);
        }
      });
      if (this.checkedTicketRulesList.length > 0) {
        if (this.checkedTicketRulesList.length === this.ticketRules.length) {
          $("#titleCheckbox").prop('checked', true);
        } else $("#titleCheckbox").prop('checked', false);
        this.$emit('setPanelVisible', this.checkedTicketRulesList);
      } else {
        this.$emit('setHeaderVisible')
      }
    },

    checkAll() {
      let ticketRulesList = this.$refs.ticketRuleList;
      this.checkedTicketRulesList = [];
      if ($('#titleCheckbox').prop('checked')) {
        ticketRulesList.forEach(element => {
          element.$refs.checkbox.checked = true;
          this.checkedTicketRulesList.push(element.ticketRule.id)
        });
        this.$emit('setPanelVisible', this.checkedTicketRulesList)
      } else {
        ticketRulesList.forEach(element => {
          element.$refs.checkbox.checked = false;
        });
        this.$emit('setHeaderVisible')
      }
    },

    sortAndOrder() {
      return this.orderBy;
    },

    equalsFields(field) {
      return this.field === field;
    },

    restoreOrders() {
      const field = window.localStorage.getItem("ticketRules.field");
      const order = window.localStorage.getItem("ticketRules.orderBy") === 'true';
      const locPrefix = window.localStorage.getItem("ticketRules.locPrefix");
      if (field != null) {
        this.field = field
        this.orderBy = order;
        this.sortByField(null, locPrefix);
      }
    },

    sortByField(field, localizedPrefix) {
      if (field != null) {
        if (Object.is(this.field, field)) {
          this.orderBy = !this.orderBy;
        } else {
          this.field = field;
          this.orderBy = true;
        }

        window.localStorage.setItem("ticketRules.field", this.field);
        window.localStorage.setItem("ticketRules.orderBy", this.orderBy);
        if (localizedPrefix != null) {
          window.localStorage.setItem("ticketRules.locPrefix", localizedPrefix);
        } else {
          window.localStorage.removeItem("ticketRules.locPrefix");
        }
      }

      if (localizedPrefix != null) {
        this.ticketRules.sort(
            App.methods.comparator(a => this.$t(localizedPrefix + '.' + a[this.field]), this.orderBy)
        );
      } else {
        this.ticketRules.sort(
            App.methods.comparator(a => a[this.field], this.orderBy)
        );
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

th, td {
  border-bottom: 1px solid grey;
}

th.cu {
  width: 23%;
}

th.ch {
  width: 1%;
}
</style>