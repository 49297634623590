<template>
  <tr>
    <td class="ticketRuleCheckboxTd">
      <input type="checkbox" @change="onChange" ref="checkbox" v-model="ticketRule.checkbox">
    </td>

    <td class="ticketRuleTypeTd" @click="getSingleTicketRule(ticketRule.id)">
      {{ $t("ticketType." + ticketRule.ticketType) }}
    </td>

    <td class="ticketRuleCommentsTd" @click="getSingleTicketRule(ticketRule.id)">
      {{ ticketRule.comments }}
    </td>

    <td class="ticketRuleAssignedTd" @click="getSingleTicketRule(ticketRule.id)">
      {{ ticketRule.assigned }}
    </td>

    <td class="ticketRuleStatusTd" @click="getSingleTicketRule(ticketRule.id)">
      {{ $t("recordStatus." + ticketRule.status) }}
    </td>
  </tr>
</template>


<script>
export default {
  props: {
    ticketRule: {
      type: Object,
      required: true
    }
  },
  methods: {
    onChange() {
      this.$emit('addRaw');
    },

    getSingleTicketRule(id) {
      this.$router.push({name: 'ticketRulesById', params: {"id": id.toString()}});
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";
</style>